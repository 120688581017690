import { Button, Col, Form, Image, Radio, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
    deleteQuestion,
    getTest,
    getQuestion,
    patchQuestion,
    postQuestion,
    postAnswer,
    patchAnswer,
    getQuestionId,
} from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { CgChevronDownR, CgChevronUpR } from "react-icons/cg";
import { message } from "antd";
import { useStore } from "../../store/Store";
import { InputImg, InputText } from "../../utils/Inputs";
import { InputSelect } from "../../utils/Inputs";
import { getBase64 } from "../../components/Objects";
import { deleteAnswer } from "../../host/Config";

export default function Questions() {
    const [form] = Form.useForm();
    const [data, setData] = useState(null);
    const [edit, setedit] = useState(null);
    const [test, setTest] = useState(null);
    const [changePage, setchangePage] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [cookie] = useCookies();
    const [answers, setAnswers] = useState([]);
    const [image_id, setimage_id] = useState(null);
    const [correct, setCorrect] = useState(false);
    const [answer, setAnswer] = useState(3);

    /* For Antd Table */
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const handleRowExpand = (expandedRows) => {
        if (expandedRows.length === 0) {
            setExpandedRowKeys([]);
        } else {
            setExpandedRowKeys([expandedRows[expandedRows.length - 1]]);
        }
    };

    const setLoader = useStore((state) => state.setLoader);

    const showModal = () => {
        setchangePage(true);
    };

    const columns = [
        {
            title: "#",
            key: "#",
            render: (text, res, index) => {
                return index + 1;
            },
        },
        {
            title: "Savol",
            key: "question",
            dataIndex: "question",
        },
        {
            title: "Rasm",
            key: "image",
            dataIndex: "id",
            width: "120px",
            render: (id, res) => {
                return res.image && <Image src={res.image} />;
            },
        },
        {
            title: "Test",
            key: "test_name",
            dataIndex: "test_name",
        },
        {
            title: "O'zgartirish",
            key: "edit",
            dataIndex: "id",
            render: (res, objc) => {
                return (
                    <Button
                        onClick={() => {
                            editData(objc);
                        }}
                        type="primary"
                    >
                        <TbEdit size={"1.3em"} />
                    </Button>
                );
            },
        },
        {
            title: "O'chirish",
            key: "delete",
            dataIndex: "id",
            render: (res) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            deleteData(res);
                        }}
                        danger
                    >
                        <MdDelete size={"1.3em"} />
                    </Button>
                );
            },
        },
    ];

    const getData = (value) => {
        getQuestion(cookie.token).then((res) => {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                let val = value.filter((item) => item.id === data[i].test);
                data[i].test_name = val.length !== 0 ? val[0].title : "";
            }
            setData(data);
            setLoader(false);
        });
    };

    const editData = (res) => {
        setImageUrl(res.image);
        if (res.answers) {
            for (let i = 0; i < res.answers.length; i++) {
                res[`answer${i + 1}`] = res.answers[i].answer;
                res[`answer_id${i + 1}`] = res.answers[i].id;
            }
            let is_correct = res.answers.findIndex((item) => item.is_correct) + 1;
            if (is_correct) {
                res.is_correct = is_correct;
            } else {
                res.is_correct = "";
            }
            setAnswers(res.answers);
            setAnswer(res.answers.length);
        }
        form.setFieldsValue(res);
        setchangePage(true);
        setedit(res.id);
        return;
    };

    const deleteData = (id) => {
        setLoader(true);
        deleteQuestion(cookie.token, id)
            .then((res) => {
                message.success("Ma'lumot o'chirildi");
                getData(test);
            })
            .catch((err) => {
                setLoader(false);
                message.error("Ma'lumot o'chirilmadi");
            });
    };

    useEffect(() => {
        getTest(cookie.token).then((res) => {
            setTest(res.data);
            getData(res.data);
        });
    }, []);

    const onFinish = (event) => {
        event.image = image_id;
        if (!event.is_correct) {
            return setCorrect(true);
        } else {
            setCorrect(false);
        }
        setLoader(true);
        let formData;
        formData = new FormData();
        formData.append("test", event.test);
        formData.append("question", event.question);
        if (edit === null) {
            if (event.image) {
                formData.append("image", event.image);
            }
            postQuestion(cookie.token, formData)
                .then((res) => {
                    message.success("Savol yaratildi");
                    try {
                        for (let i = 0; i < answer; i++) {
                            let answer = {
                                answer: event[`answer${i + 1}`],
                                is_correct: +event.is_correct === i + 1 ? true : false,
                                question: res.data.id,
                            };
                            postAnswer(cookie.token, answer).catch((err) => message.error(`${i + 1}-javob saqlanmadi`));
                        }
                    } catch {}
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot saqlanmadi");
                });
        } else {
            if (image_id) {
                formData.append("image", event.image);
            }
            patchQuestion(cookie.token, formData, edit)
                .then((res) => {
                    message.success("Ma'lumot o'zgartirildi");
                    try {
                        for (let i = 0; i < answer; i++) {
                            let new_answer = {
                                answer: event[`answer${i + 1}`],
                                is_correct: +event.is_correct === i + 1 ? true : false,
                                question: res.data.id,
                            };
                            if (answers.length < answer && answers.length < i + 1) {
                                postAnswer(cookie.token, new_answer)
                                    .then((res) => message.success(`${i + 1}-javob yaratildi`))
                                    .catch((err) => message.error(`${i + 1}-javob saqlanmadi`));
                            } else if (
                                answers[i].answer !== new_answer.answer ||
                                answers[i].is_correct !== new_answer.is_correct
                            )
                                patchAnswer(cookie.token, new_answer, answers[i].id).catch((err) =>
                                    message.error(`${i + 1}-javob saqlanmadi`)
                                );
                        }
                    } catch {}
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot o'zgartirilmadi");
                });
        }
    };

    const handleClose = () => {
        setchangePage(false);
        setimage_id(null);
        setCorrect(false);
        setImageUrl(null);
        setedit(null);
        setAnswers([]);
        setAnswer(3);
        getData(test);
        form.resetFields();
    };

    const handleChange = (info) => {
        if (info.file.status === "done") {
            setimage_id(info.file.originFileObj);
            getBase64(info.file.originFileObj, (url) => {
                setImageUrl(url);
            });
        }
    };

    const addAnswerBtn = () => {
        if (answer >= 2 && answer < 5) {
            return setAnswer(answer + 1);
        }
    };

    const colsFunc = (value) => {
        let arr = [];
        for (let i = 0; i < value; i++) {
            arr[i] = "";
        }
        return arr;
    };

    const deleteAnswers = (value) => {
        if (edit) {
            let _id = answers[value]?.id;
            if (_id) {
                setLoader(true);
                return deleteAnswer(cookie.token, _id)
                    .then((res) => {
                        message.success("Ma'lumot o'chirildi");
                        getQuestionId(cookie.token, edit)
                            .then((res1) => {
                                editData(res1);
                                setLoader(false);
                            })
                            .catch((err) => message.error("Ma'lumot topilmadi"));
                    })
                    .catch((err) => {
                        setLoader(false);
                        message.error("Ma'lumot o'chirilmadi");
                    });
            }
        }
        let data = form.getFieldsValue();
        let arr = [];
        for (let i = 0; i < answer; i++) {
            arr[i] = data[`answer${i + 1}`];
        }
        if (answer > 2) {
            colsFunc(answer - 1);
            setAnswer((prev) => prev - 1);
        }
        arr.splice(value, 1);
        for (let i = 0; i < answer; i++) {
            data[`answer${i + 1}`] = arr[i];
        }
        if (value + 1 === data.is_correct) {
            data.is_correct = "";
        }
        form.setFieldsValue(data);
    };

    return (
        <div>
            <div className="adminBox">
                {!changePage ? (
                    <>
                        <div className="admin_btns">
                            <Button style={{ marginBottom: "20px" }} onClick={showModal} type="primary">
                                Savol qo'shish
                            </Button>
                        </div>
                        <Table
                            rowKey={(res) => res.id}
                            dataSource={data}
                            columns={columns}
                            expandedRowKeys={expandedRowKeys}
                            onExpandedRowsChange={handleRowExpand}
                            expandable={{
                                expandedRowRender: (item) => (
                                    <>
                                        <p style={{ marginBottom: 10, marginLeft: 15 }}>Javoblar:</p>
                                        <ol type="A" style={{ listStylePosition: "inside" }}>
                                            {item.answers.length &&
                                                item.answers.map((item1, key) => {
                                                    return (
                                                        <li
                                                            key={key}
                                                            style={{
                                                                marginBottom: "5px",
                                                                padding: "2px 10px",
                                                                backgroundColor: item1.is_correct
                                                                    ? "#73d13d"
                                                                    : "#ff4d4f",
                                                                borderRadius: "5px",
                                                                color: "white",
                                                            }}
                                                        >
                                                            {item1.answer}
                                                        </li>
                                                    );
                                                })}
                                        </ol>
                                    </>
                                ),
                                rowExpandable: (item) => item.answers.length !== 0,
                                expandIcon: ({ expanded, onExpand, record }) => {
                                    return !expanded ? (
                                        <CgChevronUpR onClick={(e) => onExpand(record, e)} />
                                    ) : (
                                        <CgChevronDownR onClick={(e) => onExpand(record, e)} />
                                    );
                                },
                                // showExpandColumn: false,
                                expandRowByClick: true,
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <div className="admin_btns">
                                <Button style={{ marginBottom: "20px" }} onClick={handleClose} type="primary">
                                    Orqaga
                                </Button>
                                <Button
                                    style={{ marginLeft: "20px", backgroundColor: "#00a900" }}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Saqlash
                                </Button>
                            </div>
                            <Row style={{ paddingTop: "20px" }}>
                                <Col style={{ padding: "0px 20px" }} lg={18} md={18} sm={24} xs={24}>
                                    <InputText label="Savol" name="question" />
                                    <InputSelect name="test" label="Test" data={test} />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={6} md={6} sm={24} xs={24}>
                                    <InputImg
                                        label="Rasm"
                                        name="image"
                                        type="file"
                                        imageUrl={imageUrl}
                                        handleChange={handleChange}
                                        required={false}
                                    />
                                </Col>
                            </Row>
                            <Form.Item name="is_correct">
                                <Radio.Group name="is_correct">
                                    <Row style={{ paddingTop: "20px" }}>
                                        {colsFunc(answer).map((_, key) => {
                                            return (
                                                <Col
                                                    key={key}
                                                    style={{ padding: "0px 20px" }}
                                                    xxl={6}
                                                    lg={12}
                                                    md={12}
                                                    sm={24}
                                                    xs={24}
                                                >
                                                    <Row align="bottom">
                                                        <Col sm={2}>
                                                            <Radio value={key + 1} style={{ marginBottom: 30 }}></Radio>
                                                        </Col>
                                                        <Col sm={18}>
                                                            <InputText label="Javob" name={`answer${key + 1}`} />
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Button
                                                                onClick={() => deleteAnswers(key)}
                                                                style={{
                                                                    padding: "4px 8px",
                                                                    marginBottom: 24,
                                                                    marginLeft: "auto",
                                                                    display: "block",
                                                                }}
                                                                type="primary"
                                                                danger
                                                            >
                                                                <MdDelete />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            );
                                        })}
                                        {correct && (
                                            <p
                                                className="text-danger text-center w-100"
                                                style={{ fontStyle: "italic" }}
                                            >
                                                Javoblarni birini tanlash majburiy!
                                            </p>
                                        )}
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                            {answer !== 5 && (
                                <Button
                                    type="primary"
                                    style={{
                                        display: "block",
                                        width: "30%",
                                        minWidth: "120px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        marginBottom: 20,
                                    }}
                                    onClick={addAnswerBtn}
                                >
                                    Javob qo'shish
                                </Button>
                            )}
                        </Form>
                    </>
                )}
            </div>
        </div>
    );
}
