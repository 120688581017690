import React from "react";
import "../admin/css/Loader.css";

const Loader = () => {
  return (
    <div className="loader-main">
      <span className="loader-span"></span>
    </div>
  );
};

export default Loader;
