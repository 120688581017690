import { Button, Col, Form, Row, Table, Upload } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { deleteTest, getKlass, getLessons, getTest, patchTest, postTest } from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { BsEyeFill } from "react-icons/bs";
import { AiOutlineDownload } from "react-icons/ai";
import { message } from "antd";
import { useStore } from "../../store/Store";
import DescriptionModal from "../../utils/DescriptionModal";
import { InputDesc, InputNum, InputSelect, InputText } from "../../utils/Inputs";
import { UploadOutlined } from "@ant-design/icons";

const duration_unit = [
    {
        id: "hours",
        title: "Soat",
    },
    {
        id: "minutes",
        title: "Minut",
    },
    {
        id: "seconds",
        title: "Sekund",
    },
];

const status = [
    {
        id: "active",
        title: "Aktiv",
    },
    {
        id: "pending",
        title: "Kutilmoqda",
    },
    {
        id: "planned",
        title: "Rejalashtirilgan",
    },
    {
        id: "finished",
        title: "Yakunlangan",
    },
    {
        id: "cancelled",
        title: "Bekor qilingan",
    },
];

export default function Tests() {
    const [form] = Form.useForm();
    const [data, setData] = useState(null);
    const [sel_data, setSel_data] = useState(null);
    const [modal, setModal] = useState(false);
    const [edit, setedit] = useState(null);
    const [changePage, setchangePage] = useState(false);
    const [cookie, _] = useCookies();
    const [fileList, setFileList] = useState([]);
    const [klass, setKlass] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [lesson, setLesson] = useState([]);

    const setLoader = useStore((state) => state.setLoader);

    const editData = (res) => {
        setchangePage(true);
        res.klass = res.klass?.id;
        if (res.klass) {
            let lesson = lessons.filter((val) => val.klass === res.klass);
            setLesson(lesson);
        }
        res.lesson = res.lesson?.id;
        form.setFieldsValue(res);
        setedit(res.id);
    };

    const showModal = () => {
        setchangePage(true);
    };

    const columns = [
        {
            title: "#",
            key: "#",
            render: (text, res, index) => {
                return index + 1;
            },
        },
        {
            title: "Nomi",
            key: "title",
            dataIndex: "title",
        },
        {
            title: "Sinf",
            key: "klass",
            dataIndex: "klass",
            render: (res) => res?.name,
        },
        {
            title: "Mavzu nomi",
            key: "lesson",
            dataIndex: "lesson",
            render: (res) => res?.title,
        },
        {
            title: "Davomiyligi",
            key: "duration",
            dataIndex: "id",
            render: (_, res) =>
                res?.duration +
                " " +
                (res?.duration_unit === duration_unit[0].id
                    ? duration_unit[0].title
                    : res?.duration_unit === duration_unit[1].id
                    ? duration_unit[1].title
                    : duration_unit[2].title),
        },
        {
            title: "Ishlanganlar soni",
            key: "count",
            dataIndex: "count",
        },
        {
            title: "Holati",
            key: "status",
            dataIndex: "status",
            render: (_, res) => {
                let arr = status.filter((value) => res.status === value.id);
                return arr[0]?.title;
            },
        },
        {
            title: "Fayl",
            key: "file",
            dataIndex: "id",
            render: (_, res) => {
                return res.file_process_status === "finished" ? (
                    <a href={res.file} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                        <Button type="primary">
                            <AiOutlineDownload size={"1.3em"} />
                        </Button>
                    </a>
                ) : (
                    ""
                );
            },
        },
        {
            title: "Matn",
            key: "description",
            dataIndex: "id",
            render: (id, res) => {
                return (
                    <div className="table_btns">
                        <Button
                            type="primary"
                            onClick={() => {
                                setModal(true);
                                setSel_data(res);
                            }}
                        >
                            <BsEyeFill size={"1.3em"} />
                        </Button>
                    </div>
                );
            },
        },
        {
            title: "O'zgartirish",
            key: "edit",
            dataIndex: "id",
            render: (res, objc) => {
                return (
                    <Button
                        onClick={() => {
                            editData(objc);
                        }}
                        type="primary"
                    >
                        <TbEdit size={"1.3em"} />
                    </Button>
                );
            },
        },
        {
            title: "O'chirish",
            key: "delete",
            dataIndex: "id",
            render: (res) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            deleteData(res);
                        }}
                        danger
                    >
                        <MdDelete size={"1.3em"} />
                    </Button>
                );
            },
        },
    ];

    const getEventsData = () => {
        getTest(cookie.token).then((res) => {
            setData(res.data);
            setLoader(false);
        });
    };

    const deleteData = (id) => {
        setLoader(true);
        deleteTest(cookie.token, id)
            .then((res) => {
                message.success("Ma'lumot o'chirildi");
                getEventsData();
            })
            .catch((err) => {
                setLoader(false);
                message.error("Ma'lumot o'chirilmadi");
            });
    };

    useEffect(() => {
        getKlass(cookie.token).then((res) => {
            getLessons(cookie.token).then((res1) => {
                getEventsData();
                setKlass(res.data);
                setLessons(res1.data);
            });
        });
    }, []);

    const onFinish = (event) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("title", event.title);
        if (event.duration) {
            formData.append("duration", event.duration);
        }
        if (event.duration_unit) {
            formData.append("duration_unit", event.duration_unit);
        }
        if (event.count) {
            formData.append("description", event.description);
        }
        if (event.count) {
            formData.append("count", event.count);
        }
        if (event.status) {
            formData.append("status", event.status);
        }
        if (event.klass) {
            formData.append("klass", event.klass);
        }
        if (event.lesson) {
            formData.append("lesson", event.lesson);
        }
        if (edit === null) {
            if (event.file?.file?.status === "done") {
                formData.append("file", event.file.file.originFileObj);
            }
            postTest(cookie.token, formData)
                .then((res) => {
                    message.success("Ma'lumot saqlandi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot saqlanmadi");
                });
        } else {
            if (event.file.file?.status === "done") {
                formData.append("file", event.file.file.originFileObj);
            }
            patchTest(cookie.token, formData, edit)
                .then((res) => {
                    message.success("Ma'lumot o'zgartirildi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot o'zgartirilmadi");
                });
        }
    };

    const handleClose = () => {
        setchangePage(false);
        setedit(null);
        getEventsData();
        form.resetFields();
    };

    const handleChange = (info) => {
        setFileList(info.fileList);
    };

    return (
        <div>
            <div className="adminBox">
                {!changePage ? (
                    <>
                        <div className="admin_btns">
                            <Button style={{ marginBottom: "20px" }} onClick={showModal} type="primary">
                                Test qo'shish
                            </Button>
                        </div>
                        <Table rowKey={(res) => res.id} dataSource={data} columns={columns} />
                    </>
                ) : (
                    <>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFieldsChange={(value) => {
                                if (value.length !== 0) {
                                    if (value[0]?.name[0] === "klass") {
                                        let lesson = lessons.filter((val) => val.klass === value[0].value);
                                        setLesson(lesson);
                                    }
                                }
                            }}
                            autoComplete="off"
                        >
                            <div className="admin_btns">
                                <Button style={{ marginBottom: "20px" }} onClick={handleClose} type="primary">
                                    Orqaga
                                </Button>
                                <Button
                                    style={{ marginLeft: "20px", backgroundColor: "#00a900" }}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Saqlash
                                </Button>
                            </div>
                            <Row style={{ paddingTop: "20px" }}>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                                    <InputText label="Nom" name="title" />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                                    <InputNum label="Davomiyligi" name="duration" />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                                    <InputSelect label="Davomiyligi" name="duration_unit" data={duration_unit} />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                                    <InputSelect label="Holati" name="status" data={status} required={false} />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                                    <InputSelect
                                        label="Sinf"
                                        name="klass"
                                        data={klass}
                                        itemTitle="name"
                                        required={false}
                                    />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                                    <InputSelect label="Mavzu" name="lesson" data={lesson} required={false} />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                                    <InputNum label="Ishlanganlar soni" name="count" required={false} />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                                    <Form.Item label="Fayl" name="file">
                                        <Upload
                                            className="upload-file-css"
                                            name="file"
                                            customRequest={({ file, onSuccess }) => {
                                                setTimeout(() => {
                                                    onSuccess("ok");
                                                }, 0);
                                            }}
                                            maxCount={1}
                                            accept=".xlsx, .xls"
                                            onChange={handleChange}
                                            fileList={fileList}
                                        >
                                            <Button
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                                icon={<UploadOutlined />}
                                            >
                                                Tanlash
                                            </Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div style={{ padding: "15px 20px 60px 20px" }}>
                                <InputDesc label="Izoh" name="description" required={false} />
                            </div>
                        </Form>
                    </>
                )}
            </div>
            {sel_data && (
                <DescriptionModal
                    title={sel_data.title}
                    open={modal}
                    onOk={() => {
                        setModal(false);
                    }}
                    onCancel={() => {
                        setModal(false);
                    }}
                    desc={sel_data.description}
                />
            )}
        </div>
    );
}
