import { message } from "antd";

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Hajmi 2 MB dan kichkina fayl tanlang");
  }
  return isJpgOrPng && isLt2M;
};

export const beforeUploadLessons = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Hajmi 2 MB dan kichkina fayl tanlang");
  }
  return isJpgOrPng && isLt2M;
};

export const beforeUploadArticles = (file) => {
  // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  // if (!isJpgOrPng) {
  //   message.error('You can only upload JPG/PNG file!');
  // }
  console.log(file.type);
  const isLt2M = file.size / 1024 / 1024 < 50;
  if (!isLt2M) {
    message.error("Hajmi 50 MB dan kichkina fayl tanlang");
  }
  return isLt2M;
};
export const beforeUploadDic = (file) => {
  console.log(file.type);
  const isJpgOrPng = file.type.toLowerCase().includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  if (!isJpgOrPng) {
    message.error('You can only upload excel file!');
  }
  // console.log(file.type);
  // const isLt2M = file.size / 1024 / 1024 < 50;
  // if (!isLt2M) {
  //   message.error("Hajmi 50 MB dan kichkina fayl tanlang");
  // }
  return isJpgOrPng;
};

// teacher Icon
export const getBase64icon = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const beforeUploadicon = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Hajmi 2 MB dan kichkina fayl tanlang");
  }
  return isJpgOrPng && isLt2M;
};

export const beforeUploadAudio = (file) => {
  const data =
    file.type === "audio/mpeg" ||
    file.type === "audio/mp3" ||
    file.type === "audio/x-wav" ||
    file.type === "audio/ogg";
  if (!data) {
    message.error("You can only upload .mp3 .wav .ogg file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 40;
  if (!isLt2M) {
    message.error("Hajmi 40 MB dan kichkina fayl tanlang");
  }
  return data && isLt2M;
};

export const beforeUploadImg = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Hajmi 2 MB dan kichkina fayl tanlang");
  }
  return isJpgOrPng && isLt2M;
};
export const beforeUploadExcel = (file) => {
  const isJpgOrPng = file.name.toLowerCase().includes(".xlsx") || file.name.toLowerCase().includes(".xls");
  if (!isJpgOrPng) {
    message.error("You can only upload .xlsx file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Hajmi 10 MB dan kichkina fayl tanlang");
  }
  return isJpgOrPng && isLt2M;
};

export const beforeUploadFile = (file) => {
  const isLt2M = file.size / 1024 / 1024 < 50;
  if (!isLt2M) {
    message.error("Hajmi 50 MB dan kichkina fayl tanlang");
  }
  return isLt2M;
};
