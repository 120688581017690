import About from "./About";
import Articles from "./Articles";
import Audio from "./Audio";
import Categories from "./Categories";
import Course from "./Course";
import CourseDes from "./CourseDes";
import Docs from "./Docs";
import DocsCategory from "./DocsCategory";
import Events from "./Events";
import Faq from "./Faq";
import Klass from "./Klass";
import Lessons from "./Lessons";
import Links from "./Links";
import Slider from "./Slider";
import SubCategories from "./SubCategory";
import Teachers from "./Teachers";
import Videos from "./Videos";
import News from "./News";
import Presentations from "./Presentations";
import Dictionary from "./Dictionary";
import SiteInfo from "./SiteInfo";
import Questions from "./Questions";
import Students from "./Students";
import Tests from "./Test";
import Results from "./Results";
import FormReq from "./FormReq"

const routes = [
    {
        path: "",
        element: <Articles />,
    },
    {
        path: "articles",
        element: <Articles />,
    },
    {
        path: "news",
        element: <News />,
    },
    {
        path: "dictionaries",
        element: <Dictionary />,
    },
    {
        path: "presentations",
        element: <Presentations />,
    },
    {
        path: "teachers",
        element: <Teachers />,
    },
    {
        path: "lessons",
        element: <Lessons />,
    },
    {
        path: "category",
        element: <Categories />,
    },
    {
        path: "subcategory",
        element: <SubCategories />,
    },
    {
        path: "course",
        element: <Course />,
    },
    {
        path: "course-descriptions",
        element: <CourseDes />,
    },
    {
        path: "faq",
        element: <Faq />,
    },
    {
        path: "about",
        element: <About />,
    },
    {
        path: "events",
        element: <Events />,
    },
    {
        path: "links",
        element: <Links />,
    },
    {
        path: "audio",
        element: <Audio />,
    },
    {
        path: "site-info",
        element: <SiteInfo />,
    },
    {
        path: "videos",
        element: <Videos />,
    },
    {
        path: "docs",
        element: <Docs />,
    },
    {
        path: "class",
        element: <Klass />,
    },
    {
        path: "questions",
        element: <Questions />,
    },
    {
        path: "docs-categories",
        element: <DocsCategory />,
    },
    {
        path: "form-requests",
        element: <FormReq />,
    },
    {
        path: "students",
        element: <Students />,
    },
    {
        path: "tests",
        element: <Tests />,
    },
    {
        path: "results",
        element: <Results />,
    },
];

export default routes;
