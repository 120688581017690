import { Button, Form, Table } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { deleteKlass, getKlass, patchKlass, postKlass } from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { message } from "antd";
import { useStore } from "../../store/Store";
import { InputText } from "../../utils/Inputs";

export default function Klass() {
    const [form] = Form.useForm();
    const [data, setData] = useState(null);
    const [edit, setedit] = useState(null);
    const [changePage, setchangePage] = useState(false);
    const [cookie] = useCookies();

    const setLoader = useStore((state) => state.setLoader);

    const editData = (res) => {
        form.setFieldsValue(res);
        setchangePage(true);
        setedit(res.id);
    };

    const showModal = () => {
        setchangePage(true);
    };

    const columns = [
        {
            title: "#",
            key: "#",
            render: (text, res, index) => {
                return index + 1;
            },
        },
        {
            title: "Nom",
            key: "name",
            dataIndex: "name",
            width: "40vw",
        },
        {
            title: "O'zgartirish",
            key: "edit",
            dataIndex: "id",
            render: (res, objc) => {
                return (
                    <Button
                        onClick={() => {
                            editData(objc);
                        }}
                        type="primary"
                    >
                        <TbEdit size={"1.3em"} />
                    </Button>
                );
            },
        },
        {
            title: "O'chirish",
            key: "delete",
            dataIndex: "id",
            render: (res) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            deleteData(res);
                        }}
                        danger
                    >
                        <MdDelete size={"1.3em"} />
                    </Button>
                );
            },
        },
    ];

    const getData = () => {
        getKlass(cookie.token).then((res) => {
            setData(res.data);
            setLoader(false);
        });
    };

    const deleteData = (id) => {
        setLoader(true);
        deleteKlass(cookie.token, id)
            .then((res) => {
                message.success("Ma'lumot o'chirildi");
                getData();
            })
            .catch((err) => {
                setLoader(false);
                message.error("Ma'lumot o'chirilmadi");
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const onFinish = (event) => {
        setLoader(true);
        if (edit === null) {
            postKlass(cookie.token, event)
                .then((res) => {
                    message.success("Ma'lumot saqlandi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot saqlanmadi");
                });
        } else {
            patchKlass(cookie.token, event, edit)
                .then((res) => {
                    message.success("Ma'lumot o'zgartirildi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot o'zgartirilmadi");
                });
        }
    };

    const handleClose = () => {
        setchangePage(false);
        setedit(null);
        getData();
        form.resetFields();
    };

    return (
        <div>
            <div className="adminBox">
                {!changePage ? (
                    <>
                        <div className="admin_btns">
                            <Button style={{ marginBottom: "20px" }} onClick={showModal} type="primary">
                                Sinf qo'shish
                            </Button>
                        </div>
                        <Table rowKey={(res) => res.id} dataSource={data} columns={columns} />
                    </>
                ) : (
                    <>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <div className="admin_btns">
                                <Button style={{ marginBottom: "20px" }} onClick={handleClose} type="primary">
                                    Orqaga
                                </Button>
                                <Button style={{ marginLeft: "20px", backgroundColor: "#00a900" }} type="primary" htmlType="submit">
                                    Saqlash
                                </Button>
                            </div>
                            <div style={{ padding: "20px" }}>
                                <InputText label="Nom" name="name" />
                            </div>
                        </Form>
                    </>
                )}
            </div>
        </div>
    );
}
