import { Button, Checkbox, Col, Form, Input, Modal, Row, Table ,Tabs} from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  deleteFormReq,
  getFormReq,
  patchFormReq,
  getRegions
} from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { ImCheckboxUnchecked } from "react-icons/im";
import { BsEyeFill } from "react-icons/bs";
import ReactQuill from "react-quill";
import { message } from "antd";
import { useStore } from "../../store/Store";

export default function FormReq() {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [description, setdescription] = useState("");
  const [sel_data, setSel_data] = useState(null);
  const [modal, setModal] = useState(false);
  const [edit, setedit] = useState(null);
  const [cookie, setCookie] = useCookies();
  const [loading, setLoading] = useState(false);
  const [regionfilter,setRegionFilter]  = useState([])
  const setLoader = useStore((state) => state.setLoader);

  
  
  

  const columns = [
    {
      title: "#",
      key: "#",
      render: (text, res, index) => {
        return index + 1;
      },
    },
    {
      title: "F.I.SH",
      key: "fullname",
      dataIndex: "fullname",
    },
    {
      title: "Telefon raqam",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Xabar matni",
      key: "description",
      dataIndex: "id",
      render: (id, res) => {
        return (
          <div className="table_btns">
            <Button
              type="primary"
              onClick={() => {
                setModal(true);
                setSel_data(res);
              }}
            >
              <BsEyeFill size={"1.3em"} />
            </Button>
          </div>
        );
      },
    },
      {
        title: "Viloyat (shahar,tuman)",
        key: "region",
        dataIndex: "region",
        render:(res)=>{
        let  newArr =  regionfilter?.filter(c=>c.id === res)
        return(
            <div>{(newArr.length !== 0 )? newArr[0].name :""}</div>
        )
        }
      },
    {
      title: "Belgilash",
      key: "seen",
      dataIndex: "",
      render: (res) => {
        console.log(res);
        return (
          <Button
            type="primary"
            danger={!res.seen}
            onClick={()=>onFinish(res.id)}
            disabled={res.seen}
          >
            {res.seen ? "Xabar o'qilgan": "Xabar o'qilmagan"}
          </Button>
        );
      },
    },
    {
      title: "O'chirish",
      key: "delete",
      dataIndex: "id",
      render: (res) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              deleteData(res);
            }}
            danger
          >
            <MdDelete size={"1.3em"} />
          </Button>
        );
      },
    },
  ];
  const getFormReqData = () => {
    getFormReq(cookie.token).then((res) => {
      setData(res.data);
      setLoader(false);
    });
  };
  const getRegionsData = () => {
    getRegions(cookie.token).then((res) => {
        setRegionFilter(res.data.results)
      setLoader(false);
    });
  };
  const deleteData = (id) => {
    setLoader(true);
    deleteFormReq(cookie.token, id)
      .then((res) => {
        message.success("Ma'lumot o'chirildi");
        getFormReqData();
      })
      .catch((err) => {
        setLoader(false);
        message.error("Ma'lumot o'chirilmadi");
      });
  };
  useEffect(() => {
    getFormReqData();
    getRegionsData()
  }, []);

  const onFinish = (editId) => {
    console.log(editId);
    setLoader(true);
    patchFormReq(cookie.token, {seen: true}, editId)
    .then((res) => {
      message.success("Xabar o'qildi");
      handleClose();
    })
    .catch((err) => {
      setLoader(false);
      message.error("Xabar o'qilmadi");
    });
  };
  const handleClose = () => {
    getFormReqData();
};
const Tabitems = [
{
key: '1',
label: `Ko'rilmagan xabarlar`,
children: <Table
rowKey={(res) => res.id}
dataSource={data?.filter(c=>c.seen !== true)}
columns={columns}
/>,
},
{
key: '2',
label: `Ko'rilgan xabarlar`,
children: <Table
rowKey={(res) => res.id}
dataSource={data?.filter(c=>c.seen !== false)}
columns={columns}
/>,
}
];
  return (
    <div>
      <div className="adminBox">
      <Tabs defaultActiveKey="1" items={Tabitems}  />
      </div>
      <Modal
        title=""
        footer={false}
        open={modal}
        onOk={() => {
          setModal(false);
        }}
        onCancel={() => {
          setModal(false);
        }}
      >
        <div>
          <b style={{ fontSize: "18px" }}>Xabar matni</b>
          <p
            style={{ fontSize: "16px" }}
            dangerouslySetInnerHTML={{
              __html: sel_data !== null ? sel_data.description : "",
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
