import { Button, Col, Form, Row, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  deleteVideos,
  getLessons,
  getKlassesLessons,
  getVideos,
  patchVideos,
  postVideos,
} from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { BsEyeFill } from "react-icons/bs";
import { message } from "antd";
import { useStore } from "../../store/Store";
import DescriptionModal from "../../utils/DescriptionModal";
import dayjs from "dayjs";
import { InputDesc, InputSelect, InputText } from "../../utils/Inputs";

export default function Videos() {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [sel_data, setSel_data] = useState(null);
  const [modal, setModal] = useState(false);
  const [edit, setedit] = useState(null);
  const [changePage, setchangePage] = useState(false);
  const [cookie, _] = useCookies();
  const [klass, setKlass] = useState([]);
  const [selectlesson, setSelectlesson] = useState();
  const [lessons, setLessons] = useState();
  const [youtubelink, setYoutubelink] = useState(true);
  const [lessons_id, setLessons_id] = useState();
  const setLoader = useStore((state) => state.setLoader);

  const editData = (res) => {
    setchangePage(true);
    res.date = dayjs(res.date, "YYYY-MM-DD");
    res.time = dayjs(res.time, "HH:mm");
    form.setFieldsValue(res);
    setedit(res.id);
  };

  const showModal = () => {
    setchangePage(true);
  };

  const columns = [
    {
      title: "#",
      key: "#",
      render: (text, res, index) => {
        return index + 1;
      },
    },
    {
      title: "Nomi",
      key: "title",
      dataIndex: "title",
    },
    {
      title: "Youtube link ",
      key: "link",
      dataIndex: "link",
      render: (id, res) => {
        return <a href={res.link} style={{textDecoration:"none",fontSize:"16px"}} target="_blank">Havolaga o'tish</a>;
      },
    },
    {
      title: "Darslar",
      key: "lesson_title",
      dataIndex: "lesson_title",
      
    },
    {
      title: "O'zgartirish",
      key: "edit",
      dataIndex: "id",
      render: (res, objc) => {
        return (
          <Button
            onClick={() => {
              editData(objc);
            }}
            type="primary"
          >
            <TbEdit size={"1.3em"} />
          </Button>
        );
      },
    },
    {
      title: "O'chirish",
      key: "delete",
      dataIndex: "id",
      render: (res) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              deleteData(res);
            }}
            danger
          >
            <MdDelete size={"1.3em"} />
          </Button>
        );
      },
    },
  ];
  const getVideosData = () => {
    getVideos(cookie.token).then((res) => {
      setData(res.data);
    });
  };
  const getKlassData = () => {
    getKlassesLessons(cookie.token).then((res) => {
      setKlass(res.data);
    });
  };
  const getLessonsData = () =>{
    getLessons(cookie.token).then((res) => {
      setLessons(res.data)
    });
  }
  const deleteData = (id) => {
    setLoader(true);
    deleteVideos(cookie.token, id)
      .then((res) => {
        message.success("Ma'lumot o'chirildi");
        setLoader(false);

        getVideosData();
      })
      .catch((err) => {
        setLoader(false);
        message.error("Ma'lumot o'chirilmadi");
      });
  };

const sortKlass = (e) =>{
  
  let newArray = lessons.filter(c=> c.klass === e)
  setSelectlesson(newArray)
}
const sortLesson = (e) =>{
  setLessons_id(e)
}

  useEffect(() => {
    getVideosData();
    getKlassData();
    getLessonsData()
  }, []);
  const onFinish = (event) => {
    if(event.link.slice(0,30) === "https://www.youtube.com/watch?"){
    setYoutubelink(true)
    setLoader(true);
    event.lesson = lessons_id
    parseInt(event.lesson);
    const formData = new FormData();
    formData.append("title", event.title);
    formData.append("link", event.link);
    formData.append("lesson", event.lesson);
    if (edit === null) {
      postVideos(cookie.token, formData)
        .then((res) => {
          message.success("Ma'lumot saqlandi");
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          message.error("Ma'lumot saqlanmadi");
        });
    } else {
      patchVideos(cookie.token, event, edit)
        .then((res) => {
          message.success("Ma'lumot o'zgartirildi");
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          message.error("Ma'lumot o'zgartirilmadi");
        });
    }
  }
  else{
    setYoutubelink(false)
  }
  };

  const handleClose = () => {
    setLoader(false);
    setchangePage(false);
    setYoutubelink(true)
    setedit(null);
    getVideosData();
    form.resetFields();
  };

  return (
    <div>
      <div className="adminBox">
        {!changePage ? (
          <>
            <div className="admin_btns">
              <Button
                style={{ marginBottom: "20px" }}
                onClick={showModal}
                type="primary"
              >
                Video qo'shish
              </Button>
            </div>
            <Table
              rowKey={(res) => res.id}
              dataSource={data}
              columns={columns}
            />
          </>
        ) : (
          <>
            <Form
              form={form}
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <div className="admin_btns">
                <Button
                  style={{ marginBottom: "20px" }}
                  onClick={handleClose}
                  type="primary"
                >
                  Orqaga
                </Button>
                <Button
                  style={{ marginLeft: "20px", backgroundColor: "#00a900" }}
                  type="primary"
                  htmlType="submit"
                >
                  Saqlash
                </Button>
              </div>
              <Row style={{ paddingTop: "20px" }}>
                <Col style={{ padding: "0px 20px" }} lg={9} md={24}>
                  <InputText label="Nomi" name="title" />
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={15} md={24}>
                  <InputText label={`Youtube link namuna:  https://www.youtube.com/watch?v=ddNOduog-C0`} name="link" placeholder="https://www.youtube.com/watch?v=ddNOduog-C0" />
                  {youtubelink ? "" : <p style={{color:"red"}}>Havola noto'g'ri kiritilgan namunaga e'tibor bering !!!</p>}
                </Col>
              </Row>
              <Row>
                <Col lg={9} md={24}>
                
                <div style={{ padding: "15px 20px 60px 20px" }}>
                <Form.Item label="Sinf tanlash" name="klass" required={true}>
                  <Select style={{ width: 220 }} placeholder={"Sinf tanlash"} onChange={sortKlass}>
                    {klass.length !== 0 &&
                      klass.map((item, key) => {
                        return (
                           <Select.Option key={key} value={item.id}>
                           {item.name}
                         </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                
              </div>
                </Col>
                <Col lg={9} md={24}>
                <div style={{ padding: "15px 20px 60px 20px" }}>
                <Form.Item label="Mavzu tanlash" name="lesson" required={true}>
                  <Select style={{ width: 220 }} placeholder={"Mavzu tanlash"} onChange={sortLesson}>
                    {selectlesson  &&
                      selectlesson.map((item, key) => {
                        return (
                          <Select.Option key={key} value={item.id}>
                           {item.title}
                         </Select.Option>
                         
                        );
                      })}
                  </Select>
                </Form.Item>
                
              </div>
                </Col>
              
              </Row>
            </Form>
          </>
        )}
      </div>
    </div>
  );
}
