import { Button, Col, Form, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { deleteStudent, getKlass, getRegion, getStudent, patchStudent, postStudent } from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { message } from "antd";
import { useStore } from "../../store/Store";
import { InputPassword, InputText } from "../../utils/Inputs";
import { InputSelect } from "../../utils/Inputs";

export default function Students() {
    const [form] = Form.useForm();
    const [data, setData] = useState(null);
    const [edit, setedit] = useState(null);
    const [klass, setKlass] = useState(null);
    const [region, setRegion] = useState(null);
    const [changePage, setchangePage] = useState(false);
    const [cookie] = useCookies();

    const setLoader = useStore((state) => state.setLoader);

    const editData = (res) => {
        res.region = res.region.id;
        res.klass = res.klass.id;
        form.setFieldsValue(res);
        setchangePage(true);
        setedit(res.id);
    };

    const showModal = () => {
        setchangePage(true);
    };

    const columns = [
        {
            title: "#",
            key: "#",
            render: (text, res, index) => {
                return index + 1;
            },
        },
        {
            title: "F.I.Sh.",
            key: "name",
            dataIndex: "name",
            render: (_, res) => {
                return res?.last_name + " " + res?.first_name;
            },
        },
        {
            title: "Nom",
            key: "username",
            dataIndex: "username",
        },
        {
            title: "Maktab",
            key: "school",
            dataIndex: "school",
        },
        {
            title: "Sinf",
            key: "klass",
            dataIndex: "klass",
            render: (res) => res?.name,
        },
        {
            title: "Hudud",
            key: "region",
            dataIndex: "region",
            render: (res) => res?.name,
        },
        {
            title: "Manzil",
            key: "address",
            dataIndex: "address",
        },
        {
            title: "O'zgartirish",
            key: "edit",
            dataIndex: "id",
            render: (res, objc) => {
                return (
                    <Button
                        onClick={() => {
                            editData(objc);
                        }}
                        type="primary"
                    >
                        <TbEdit size={"1.3em"} />
                    </Button>
                );
            },
        },
        {
            title: "O'chirish",
            key: "delete",
            dataIndex: "id",
            render: (res) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            deleteData(res);
                        }}
                        danger
                    >
                        <MdDelete size={"1.3em"} />
                    </Button>
                );
            },
        },
    ];

    const getData = () => {
        getStudent(cookie.token).then((res) => {
            setData(res.data.results);
            setLoader(false);
        });
    };

    const deleteData = (id) => {
        setLoader(true);
        deleteStudent(cookie.token, id)
            .then((res) => {
                message.success("Ma'lumot o'chirildi");
                getData();
            })
            .catch((err) => {
                setLoader(false);
                message.error("Ma'lumot o'chirilmadi");
            });
    };

    useEffect(() => {
        getRegion(cookie.token).then((res) => {
            getKlass(cookie.token).then((res1) => {
                setRegion(res.data.results);
                setKlass(res1.data);
                getData();
            });
        });
    }, []);

    const onFinish = (event) => {
        setLoader(true);
        let data = {
            last_name: event.last_name,
            first_name: event.first_name,
            username: event.username,
            address: event.address,
            region: event.region,
            school: event.school,
            klass: event.klass,
        };
        if (edit === null) {
            data.password = event.password;
            postStudent(cookie.token, data)
                .then((res) => {
                    message.success("Ma'lumot saqlandi");
                    handleClose();
                })
                .catch((err) => {
                    message.error("Ma'lumot saqlanmadi");
                    if (err.response.data?.username) {
                        message.error("Foydalanuvchi nomi mavjud");
                    }
                    setLoader(false);
                });
        } else {
            if (event.password?.length !== 0) {
                data.password = event.password;
            }
            patchStudent(cookie.token, data, edit)
                .then((res) => {
                    message.success("Ma'lumot o'zgartirildi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    if (err.response.data?.username) {
                        message.error("Foydalanuvchi nomi mavjud");
                    }
                    message.error("Ma'lumot o'zgartirilmadi");
                });
        }
    };

    const handleClose = () => {
        setchangePage(false);
        setedit(null);
        getData();
        form.resetFields();
    };

    return (
        <div>
            <div className="adminBox">
                {!changePage ? (
                    <>
                        <div className="admin_btns">
                            <Button style={{ marginBottom: "20px" }} onClick={showModal} type="primary">
                                O'quvchi qo'shish
                            </Button>
                        </div>
                        <Table rowKey={(res) => res.id} dataSource={data} columns={columns} />
                    </>
                ) : (
                    <>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <div className="admin_btns">
                                <Button style={{ marginBottom: "20px" }} onClick={handleClose} type="primary">
                                    Orqaga
                                </Button>
                                <Button
                                    style={{ marginLeft: "20px", backgroundColor: "#00a900" }}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Saqlash
                                </Button>
                            </div>
                            <Row style={{ paddingTop: "20px" }}>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={12} sm={24} xs={24}>
                                    <InputText label="Ism" name="first_name" />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={12} sm={24} xs={24}>
                                    <InputText label="Familya" name="last_name" />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={12} sm={24} xs={24}>
                                    <InputText label="Nom" name="username" />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={12} sm={24} xs={24}>
                                    <InputPassword label="Parol" name="password" required={edit ? false : true} />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={24} md={16} sm={24} xs={24}>
                                    <InputText label="Maktab" name="school" />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={8} sm={12} xs={24}>
                                    <InputSelect name="region" label="Hudud" data={region} itemTitle="name" />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={8} sm={12} xs={24}>
                                    <InputSelect name="klass" label="Sinf" data={klass} itemTitle="name" />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={24} md={16} sm={24} xs={24}>
                                    <InputText label="Manzil" name="address" />
                                </Col>
                            </Row>
                        </Form>
                    </>
                )}
            </div>
        </div>
    );
}
