import { Button, Col, Form, Row, Select, Table } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { deletePresentations, getDocsCategory, getPresentations, patchPresentations, postPresentations, getKlassesLessons, getLessons } from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { message } from "antd";
import { useStore } from "../../store/Store";
import { InputFile, InputText } from "../../utils/Inputs";
import { InputSelect } from "../../utils/Inputs";

export default function Presentations() {
    const [form] = Form.useForm();
    const [data, setData] = useState(null);
    const [edit, setedit] = useState(null);
    const [changePage, setchangePage] = useState(false);
    const [klass, setKlass] = useState([]);
    const [lessons, setLessons] = useState();
    const [selectlesson, setSelectlesson] = useState();
    const [lessons_id, setLessons_id] = useState();
    const [cookie] = useCookies();
    const [disabledFile, setDisabledFile] = useState(false);
    const [disabledLink, setDisabledLink] = useState(false);
    const [defaultFile, setDefaultFile] = useState([]);

    const setLoader = useStore((state) => state.setLoader);

    const editData = (res) => {
        if (res.file) {
            let file = res.file;
            setDefaultFile([
                {
                    uid: 1,
                    status: "done",
                    url: file,
                    name: file.slice(file.lastIndexOf("/") + 1),
                },
            ]);
            res.file = {
                fileList: [
                    {
                        uid: 1,
                        status: "done",
                        url: file,
                        name: file.slice(file.lastIndexOf("/") + 1),
                    },
                ],
            };
            setDisabledLink(true);

        } else {
            setDisabledFile(true);
        }
        let klass = lessons.filter(c=>c.id === res.lesson)
        res.klass = klass.length !== 0 ? klass[0].klass: "" 
        sortKlass(res.klass)
        form.setFieldsValue(res);
        setchangePage(true);
        setedit(res.id);

    };


    const showModal = () => {
        setchangePage(true);
    };

    const columns = [
        {
            title: "#",
            key: "#",
            render: (text, res, index) => {
                return index + 1;
            },
        },
        {
            title: "Nomi",
            key: "title",
            dataIndex: "title",
        },
        {
            title: "Hujjat",
            key: "file",
            dataIndex: "id",
            width: "232px",
            render: (id, res) => {
                return (
                    <div
                        style={{
                            maxWidth: "200px",
                        }}
                    >
                        {(res.link || res.file) && (
                            <a href={res.link ? res.link : res.file} target="_blank" rel="noreferrer" style={{ textDecoration: "none", textAlign: "center", display: "inline-block", width: "100%" }}>
                                {(res.link || res.file) && "Havolaga o'tish"}
                            </a>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Mavzu",
            key: "lesson_name",
            dataIndex: "lesson_name",
        },
        {
            title: "O'zgartirish",
            key: "edit",
            dataIndex: "id",
            render: (res, objc) => {
                return (
                    <Button
                        onClick={() => {
                            editData(objc);
                        }}
                        type="primary"
                    >
                        <TbEdit size={"1.3em"} />
                    </Button>
                );
            },
        },
        {
            title: "O'chirish",
            key: "delete",
            dataIndex: "id",
            render: (res) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            deleteData(res);
                        }}
                        danger
                    >
                        <MdDelete size={"1.3em"} />
                    </Button>
                );
            },
        },
    ];

    const getData = (value = []) => {
        getPresentations(cookie.token).then((res) => {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                let val = value.filter((item) => item.id === data[i].lesson);
                data[i].lesson_name = val.length !== 0 ? val[0].title : "";
            }
            setData(data);
            setLoader(false);
        });
    };

    const getKlassData = () => {
        getKlassesLessons(cookie.token).then((res) => {
            setKlass(res.data);
        });
    };

    const getLessonsData = () => {
        getLessons(cookie.token).then((res) => {
            setLessons(res.data);
            getData(res.data);
        });
    };

    const sortKlass = (e) => {
        let newArray = lessons.filter((c) => c.klass === e);
        setSelectlesson(newArray);
        if(newArray.length){form.setFieldValue('lesson',newArray[0].id)}
        else{
            form.setFieldValue('lesson', "")
        }
    };


    const deleteData = (id) => {
        setLoader(true);
        deletePresentations(cookie.token, id)
            .then((res) => {
                message.success("Ma'lumot o'chirildi");
                getData(lessons);
            })
            .catch((err) => {
                setLoader(false);
                message.error("Ma'lumot o'chirilmadi");
            });
    };

    useEffect(() => {
            getKlassData();
            getLessonsData();
    }, []);

    const onFinish = (event) => {
        setLoader(true);
        parseInt(event.lesson);
        let formData = new FormData();
        formData.append("title", event.title);
        formData.append("lesson", event.lesson);
        if (event.link && !disabledLink) {
            formData.append("link", event.link);
        } else {
            formData.append("link", "");
        }
        if (event.file && event.file.fileList && event.file.fileList !== 0 && !disabledFile) {
            if (event.file.fileList[0].originFileObj) {
                formData.append("file", event.file.fileList[0].originFileObj);
            }
        } else {
            formData.append("file", "");
        }
        if (edit === null) {
            postPresentations(cookie.token, formData)
                .then((res) => {
                    message.success("Ma'lumot saqlandi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot saqlanmadi");
                });
        } else {
            patchPresentations(cookie.token, formData, edit)
                .then((res) => {
                    message.success("Ma'lumot o'zgartirildi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot o'zgartirilmadi");
                });
        }
    };

    const handleClose = () => {
        setchangePage(false);
        setedit(null);
        setDisabledFile(false);
        setDisabledLink(false);
        getData(lessons);
        setDefaultFile([])
        form.resetFields();
    };

    const handleChange = (info) => {
        if (info.file.status === "done") {
            setDefaultFile(info.fileList);
            setDisabledLink(true);
        } else {
            setDefaultFile(info.fileList);
            setDisabledLink(false);
        }
    };

    return (
        <div>
            <div className="adminBox">
                {!changePage ? (
                    <>
                        <div className="admin_btns">
                            <Button style={{ marginBottom: "20px" }} onClick={showModal} type="primary">
                                Prezentatsiya qo'shish
                            </Button>
                        </div>
                        <Table rowKey={(res) => res.id} dataSource={data} columns={columns} />
                    </>
                ) : (
                    <>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFieldsChange={(e, allE) => {
                                if (allE[3].value && allE[3].value.fileList.length !== 0) {
                                    setDisabledLink(true);
                                } else if (allE[4].value && allE[4].value.length !== 0) {
                                    setDisabledFile(true);
                                    form.setFieldValue("file");
                                } else {
                                    setDisabledLink(false);
                                    setDisabledFile(false);
                                }
                            }}
                            autoComplete="off"
                        >
                            <div className="admin_btns">
                                <Button style={{ marginBottom: "20px" }} onClick={handleClose} type="primary">
                                    Orqaga
                                </Button>
                                <Button style={{ marginLeft: "20px", backgroundColor: "#00a900" }} type="primary" htmlType="submit">
                                    Saqlash
                                </Button>
                            </div>
                            <Row style={{ paddingTop: "20px" }}>
                                <Col style={{ padding: "0px 20px" }} lg={24} md={12} sm={24} xs={24}>
                                    <InputText label="Nom" name="title" />
                                </Col>
                                <Col lg={8} md={6} sm={12} xs={24} style={{ padding: "0px 20px" }}>
                                    <div >
                                        <Form.Item label="Sinf tanlash" name="klass" required={true}>
                                            <Select  placeholder={"Sinf tanlash"} onChange={sortKlass}>
                                                {klass.length !== 0 &&
                                                    klass.map((item, key) => {
                                                        return (
                                                            <Select.Option key={key} value={item.id}>
                                                                {item.name}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={8} md={6} sm={12} xs={24} style={{ padding: "0px 20px" }}>
                                    <div >
                                        <Form.Item label="Mavzu tanlash" name="lesson" required={true}>
                                            <Select  placeholder={"Mavzu tanlash"} >
                                                {selectlesson &&
                                                    selectlesson.map((item, key) => {
                                                        return (
                                                            <Select.Option key={key} value={item.id}>
                                                                {item.title}
                                                            </Select.Option>
                                                        );
                                                    })}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={8} md={6} sm={12} xs={24}>
                                    <InputFile label="Hujjat" name="file" type="file" required={!disabledFile} disabled={disabledFile} fileList={defaultFile} handleChange={handleChange} />
                                </Col>
                            </Row>
                            <Row>
                               <Col style={{ padding: "0px 20px" }} lg={24} md={18} sm={24} xs={24}>
                                    <InputText label="Havola" name="link" required={!disabledLink} disabled={disabledLink} />
                                </Col>
                                <p className="text-danger text-center w-100" style={{ fontStyle: "italic" }}>
                                    Hujjat yoki havolani to'ldirish majburiy!
                                </p>
                            </Row>
                        </Form>
                    </>
                )}
            </div>
        </div>
    );
}
