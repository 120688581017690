import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getResults } from "../../host/Config";
import { useStore } from "../../store/Store";

export default function Results() {
    const [data, setData] = useState([]);
    const [cookie] = useCookies();

    const setLoader = useStore((state) => state.setLoader);

    const columns = [
        {
            title: "#",
            key: "#",
            render: (text, res, index) => {
                return index + 1;
            },
        },
        {
            title: "F.I.Sh.",
            key: "student",
            dataIndex: "student",
            render: (res) => {
                return res?.last_name + " " + res?.first_name;
            },
        },
        {
            title: "Ball",
            key: "score",
            dataIndex: "score",
        },
        {
            title: "Test",
            key: "test",
            dataIndex: "test",
            render: (value) => value?.title,
        },
    ];

    const getData = () => {
        getResults(cookie.token)
            .then((res) => {
                setData(res.data.results);
                setLoader(false);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <div className="adminBox">
                <div className="admin_btns">
                    <Button style={{ marginBottom: "20px" }} onClick={() => getData()} type="primary">
                        Yangilash
                    </Button>
                </div>
                <Table rowKey={(res) => res.id} dataSource={data} columns={columns} />
            </div>
        </div>
    );
}
