import { GeolocationControl, Map, Placemark, YMaps } from "react-yandex-maps";

export default function YandexMap({ coords, handleMapClick }) {
  return (
    <div
      style={{
        width: "100%",
        height: "50vh",
        minHeight: "300px",
        position: "relative",
      }}
    >
      <YMaps
        query={{
          ns: "use-load-option",
          load: "Map,Placemark,control.ZoomControl,control.FullscreenControl",
          lang: "uz_Uz",
        }}
        width="100%"
      >
        <Map
          onClick={handleMapClick}
          width="100%"
          height="100%"
          defaultState={{
            center: [coords[0], coords[1]],
            zoom: 12,
            controls: ["zoomControl", "fullscreenControl"],
          }}
        >
          <Placemark
            geometry={[coords[0], coords[1]]}
            properties={{
              balloonContentBody:
                "This is balloon loaded by the Yandex.Maps API module system",
            }}
          />
          <GeolocationControl
            options={{
              float: "left",
            }}
            state={[coords[0], coords[1]]}
          />
        </Map>
      </YMaps>
    </div>
  );
}
