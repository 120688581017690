import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import "../css/Admin.css";
import { Button, Layout, Menu, theme } from "antd";
import { Route, Routes, useLocation } from "react-router";
import { menuItems, menuLinks } from "./MenuItems";
import routes from "./routes";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../store/Store";
import Loader from "../../components/Loader";

import { BsArrowLeftSquareFill } from "react-icons/bs";
import { useCookies } from "react-cookie";
const { Header, Sider, Content } = Layout;

const Admin = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [cookies, setCookie,removeCookie] = useCookies(["token"]);
  const { setLogin } = useStore((state) => state);
  const loader = useStore((state) => state.loader);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  
  var location = useLocation();
  var path = location.pathname.slice(location.pathname.indexOf("/") + 7);
  if (path.length === 0) {
    path = "articles";
  }
const Logout = () =>{
  removeCookie('token')
  setLogin(false)
}
  const pathId = menuLinks[path];

  return (
    <div className="adminPanel">
      {loader ? (
        <Loader />
      ) : (
        <Layout>
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className="logo">{collapsed ? "" : <>Teacher.uz</>}</div>
            <Menu theme="light" mode="inline" defaultSelectedKeys={[pathId]} >
              <div
                className="btnMenu"
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </div>
              {/* <div style={{paddingLeft:"10px",overflowY:"scroll",height:"700px"}}> */}
              {menuItems.map((item) => {
                return (
                  <Menu.Item key={item.key}>
                    <item.icon />
                    <Link to={item.path}>{item.name}</Link>
                  </Menu.Item>
                );
              })}
              {/* </div> */}
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header
              style={{
                padding: 0,
                background: colorBgContainer,
              }}
            >
              <div>
                <Button type="primary"  danger style={{float:"right",marginRight:"10px",marginTop:"10px",fontSize:"15px",paddingTop:"2px"}} onClick={Logout} ><BsArrowLeftSquareFill size={"18px"}/> &nbsp;&nbsp;Chiqish</Button>
              </div>
            </Header>
            <Content
              style={{
                margin: "24px 16px",
                padding: 24,
                minHeight: 280,
                background: colorBgContainer,
              }}
            >
              <Routes>
                {routes.map((item, key) => {
                  return (
                    <Route key={key} path={item.path} element={item.element} />
                  );
                })}
              </Routes>
            </Content>
          </Layout>
        </Layout>
      )}
    </div>
  );
};
export default Admin;
